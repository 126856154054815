@import '../common/variables';
.template-and-contact-wrapper {
  background-color: transparent;
}
.white-wrapper {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
}
.css-1s2u09g-control1 {
  .css-1s2u09g-control {
    border-color: #dc3545 !important;
  }
}
.templateblock {
  border: 1px solid$sixth;
  border-radius: 5px;
  .block-1 {
    min-height: 165px;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    .badge-bl {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
  .block-2 {
    padding: 20px;
    display: flex;
    background-color: #f5f6f8;
    border-radius: 0px 0 5px 5px;
    .bl_1 {
      flex-grow: 1;
    }
  }
  @media screen and (max-width: 1399px) {
    margin-bottom: 20px;
  }
  &.folder {
    .block-2 {
      border-radius: 5px;
      background-color: #ffffff;
    }
  }
  &.op-down {
    opacity: 0.6;
  }
}

.dropdown.show {
  .tlg {
    background-color: #f5f6f8;
  }
}
.tlg {
  display: inline-flex;
  padding: 10px 5px;
}
.select-component {
  width: 25%;
  @media screen and (max-width: 991px) {
    width: 50%;
  }
  @media screen and (max-width: 575px) {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.custome-select-mk {
  .css-1insrsq-control {
    background-color: #e9ecef;
    height: 50px;
    border-radius: 10px;
    border-color: #dadce0;
  }
}
.column_list {
  display: flex;
  flex-wrap: wrap;
  .element {
    width: calc(20% - 16px);
    margin-right: 20px;
    &:nth-child(5n) {
      margin-right: 0;
      margin-bottom: 20px;
    }
    @media screen and (max-width: 1599px) {
      width: calc(25% - 16px);
      margin-right: 20px;
      &:nth-child(4n) {
        margin-right: 0;
        margin-bottom: 20px;
      }
      &:nth-child(5n) {
        margin-right: 20px;
        margin-bottom: 0px;
      }
    }
    @media screen and (max-width: 1399px) {
      width: calc(33% - 11px);
      margin-right: 20px;
      &:nth-child(3n) {
        margin-right: 0;
        margin-bottom: 0px;
      }
      &:nth-child(5n) {
        margin-right: 20px;
        margin-bottom: 0px;
      }
      &:nth-child(4n) {
        margin-right: 20px;
        margin-bottom: 0px;
      }
    }
    @media screen and (max-width: 1399px) {
      width: calc(50% - 10px);
      margin-right: 20px;
      &:nth-child(3n) {
        margin-right: 20px;
      }
      &:nth-child(2n) {
        margin-right: 0;
        margin-bottom: 0px;
      }
    }
    @media screen and (max-width: 575px) {
      width: 100%;
      margin-right: 00px;
      &:nth-child(3n) {
        margin-right: 00px;
      }
      &:nth-child(2n) {
        margin-right: 0;
        margin-bottom: 0px;
      }
      &:nth-child(5n) {
        margin-right: 0px;
        // margin-bottom: 0px;
      }
    }
  }
}
.documentation-list {
  height: 288px;
}
.ct-table th,
.ct-table td {
  padding: 15px 20px;
}

.erc-template-wrapper {
  padding: 30px 30px 40px 30px;
  background-color: #fff;
  width: 370px;
  border-radius: 20px;
}
.modal-pad-30 {
  padding: 30px;
}
.list-inforation {
  width: 100%;

  li {
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    .ct_2,
    .label_bl {
      width: calc(50% - 15px);
    }
    .cln {
      width: 30px;
      display: inline-flex;
      align-items: center;
      align-items: center;
      justify-content: center;
    }
  }
}

.custom-stepper-popup .custom-card-wrapper {
  padding: 30px !important;
}

/*business detail pop up start */
.busin_element {
  display: flex;
  // align-items: center;
  margin-bottom: 10px;
  .fir_bl {
    display: inline-flex;
    width: 140px;
  }
  .colon_bl {
    width: 40px;
    display: inline-flex;
    justify-content: center;
    // align-items: center;
  }
  .sec_bl {
    width: calc(100% - 180px);
    display: inline-flex;
  }
}
.documentation-list {
  height: auto;
  li {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 0px;
    &:last-child {
      border-color: transparent;
    }
    @media screen and (max-width: 991px) {
      padding: 7px 0;
    }
  }
}
/*business detail pop up end */

/*static icons pop up sucessful and failed pop up*/
.big-font-icons {
  font-size: 60px;
  &.green {
    color: #32bea6;
  }
  &.red {
    color: #e04f5f;
  }
}

/*upload pop up css*/
.upload-wrapper {
  .box {
    background-image: repeating-linear-gradient(
        -25deg,
        #dadce0,
        #dadce0 6px,
        transparent 6px,
        transparent 12px,
        #dadce0 12px
      ),
      repeating-linear-gradient(
        65deg,
        #dadce0,
        #dadce0 6px,
        transparent 6px,
        transparent 12px,
        #dadce0 12px
      ),
      repeating-linear-gradient(
        155deg,
        #dadce0,
        #dadce0 6px,
        transparent 6px,
        transparent 12px,
        #dadce0 12px
      ),
      repeating-linear-gradient(
        245deg,
        #dadce0,
        #dadce0 6px,
        transparent 6px,
        transparent 12px,
        #dadce0 12px
      );
    background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.menu {
  font-size: 14px;
  background-color: #fff;
  border-radius: 2px;
  padding: 5px 0 5px 0;
  width: 150px;
  height: auto;
  margin: 0;
  /* use absolute positioning  */
  position: absolute;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  opacity: 1;
  transition: opacity 0.5s linear;
}
