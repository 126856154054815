html {
    scroll-behavior: smooth;
}

.chat-main {
    background-color: #170a35;
    height: 94vh !important;
}

.chat-sidebar {
    background-color: #2a1651;
    height: 100%;
    width: 300px;
    border-radius: 0 35px 0 0;
    color: #fff;
    padding: 40px 0;

    .chat-sidebar-btn {
        padding: 0 30px;
    }

    .chat-sidebar-list {
        margin-top: 50px;

        .list-section {
            padding: 15px 30px;
            font-size: 16px;
            cursor: pointer;

            &:hover {
                background-color: #7e55d7;
            }
        }
    }
}

.chat-btn {
    background-color: #0a1832;
    border-color: #fff;
    font-weight: 700;
    padding: 8px;

    &:hover {
        background-color: #0a1832;
    }
}

.chat-list-user {
    width: 60px;
    height: 60px;
    background-color: #1967a9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat-list-user-send {
    width: 60px;
    height: 60px;
    margin-left: 25px;
    background-color: #0a1832;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
    }
}

.chat-card {
    width: 400px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0a1832;
    padding: 20px;
    border-radius: 10px;
}

.chat-list {
    background: transparent linear-gradient(197deg, #1967a9 0%, #0a1832 100%) 0% 0% no-repeat padding-box;
    width: 100%;
    color: #fff;
    padding: 0px 0 40px 0;

    pre {
        font-family: lato;
    }

    .chat-list-container {
        display: flex;
        height: 100%;
        width: 100%;

        .chat-list-reply {
            overflow: auto;
            font-size: 16px;
            width: 100%;
            padding-bottom: 120px;

            &::-webkit-scrollbar {
                display: none;
            }

            .chat-list-send-api {
                display: flex;
                gap: 20px;
                // padding: 20px;
                // background-color: #0d5282;
                border-radius: 10px;
                margin: 20px 0;
            }

            .chat-list-reply-api {
                display: flex;
                gap: 20px;
                background: #0a1832;

                padding: 20px;
                border-radius: 10px;
            }

            // react-code-blocks package copy button hidden
            .sc-dcrmVg {
                display: none;
            }
        }

        .chat-list-search {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #0a1832;
            padding: 15px 30px;
            font-size: 16px;
            box-shadow: 0px -6px 52px -15px #1967a9, 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
            border-radius: 10px;

            .chat-list-feature {
                width: 20%;
                text-align: center;
                background-color: #2a1651;
                padding: 10px;
                border-radius: 10px;
                cursor: pointer;
            }

            textarea {
                background-color: #0a1832;
                border: none;
                color: #fff;
                outline: #0a1832;
                width: 95%;
                transition: all 0.1s;

                &::placeholder {
                    color: #fff;
                }
            }
        }

        .feature-title-remove {
            background-color: #37092f;
            padding: 5px 20px;
            border-radius: 5px;
            color: #900518;
            font-weight: 700;
            cursor: pointer;

            .disabled {
                background-color: red;
            }
        }

        .feature-title-remove,
        .disabled {
            opacity: 0.9;
        }

        .feature-title-selected {
            background-color: #134813;
            padding: 5px 20px;
            border-radius: 5px;
            color: #2d9957;
            font-weight: 700;
        }

        .chat-list-search-feature {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            position: absolute;
            bottom: 0;
            justify-content: space-around;
            align-items: center;
            background: #091a36;
            padding: 15px 30px;
            font-size: 16px;
            border-radius: 10px;

            .chat-list-feature {
                width: 22%;
                text-align: center;
                background: transparent linear-gradient(259deg, #1967a9 0%, #0a1832 100%) 0% 0% no-repeat padding-box;
                padding: 10px;
                border-radius: 10px;
                cursor: pointer;
            }
        }
    }
}

code {
    color: #fff !important;
}

.sc-kuWgmH {
    border-color: #16202d !important;
    background-color: #16202d !important;

    span:first-child {
        border-color: #16202d !important;
        background-color: #16202d !important;
    }
}

.response-error {
    border: 1px solid rgba(245, 0, 0, 0.671);
    width: 100%;
    border-radius: 10px;
    padding: 10px 20px;
    background: #ff000044;
}