@import '../common/variables';
.channelmanagement-wrapper {
  .channel-table {
    th:last-child {
      width: 215px;
      min-width: 215px;
    }
  }
}
.right-block-resn {
  @media screen and (max-width: 991px) {
    width: 100% !important;
    margin-top: 20px;
  }
}

.ct-modal-wrapper {
  display: flex;
  @media screen and (max-width: 991px) {
    display: block;
  }
  .left-block {
    padding: 20px 30px 20px 20px;
    display: inline-flex;

    width: calc(100% - 400px);
    flex-direction: column;
    @media screen and (max-width: 1399px) {
      width: calc(100% - 300px);
    }
    @media screen and (max-width: 991px) {
      width: 100%;
      max-height: 350px;
    }
  }
  .right-block {
    width: 400px;
    background-color: #f5f6f8;
    border-radius: 10px;
    padding: 20px 20px 20px 20px;
    .form-wrapper {
      display: flex;
      height: 100%;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
    }
    @media screen and (max-width: 1399px) {
      width: 300px;
    }
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
}

.ct-modal-wrapper-read {
  display: flex;
  @media screen and (max-width: 991px) {
    display: block;
  }
  .left-block {
    padding: 20px 30px 20px 20px;
    display: inline-flex;
    width: calc(100% - 270px);
    flex-direction: column;
    @media screen and (max-width: 1399px) {
      width: calc(100% - 250px);
    }
    @media screen and (max-width: 991px) {
      width: 100%;
      max-height: 250px;
    }
  }
  .right-block {
    width: 270px;
    background-color: #f5f6f8;
    border-radius: 10px;
    padding: 20px 20px 20px 20px;
    .form-wrapper {
      display: flex;
      height: 100%;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
    }
    @media screen and (max-width: 1399px) {
      width: 250px;
    }
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
}

.custom-popup-channel {
  .custom-card-wrapper {
    height: 440px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 20px;
    @media screen and (max-width: 991px) {
      height: auto;
    }
  }
  .custom-blockchain-explorer {
    height: 250px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 20px;
    @media screen and (max-width: 991px) {
      height: auto;
    }
  }
}

.invitation-popup {
  .custom-card-wrapper {
    padding: 30px;
  }
  .sendlink {
    position: absolute;
    top: 25px;
    right: 15px;
    width: 44px;
    transform: translate(0, -50%);
    .link-resend {
      font-size: 14px;
      transition: all ease 0.3s;
      color: $forth;
      &:hover {
        color: $primary;
      }
    }
  }
}
.create-channel-pop {
  .custom-card-wrapper {
    padding: 30px;
  }
}
.form-select {
  border: 1px solid $sixth;
  height: 50px;
  border: 1px solid $sixth;
  border-radius: 10px;
  font-size: 14px;
  line-height: 17px;
  color: $ninth;
  box-shadow: none !important;
  outline: none !important;
  &:focus {
    border-color: $primary;
    color: #868686;
  }
}
