/* hide badge */

html body .reactour_touristGuide {
  max-width: 620px;
  width: 95%;
  padding: 13px 30px;
  margin: 10px;
  border-radius: 10px;
  /* top: -243px; */
}

.desKFk {
  opacity: 0.5;
}

.reactour_touristGuide div[data-tour-elem='controls'] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reactour__close {
  top: 10px !important;
  right: 15px !important;
}

.reactour_touristGuide button[data-tour-elem='left-arrow'],
.reactour_touristGuide button[data-tour-elem='right-arrow'] {
  color: #073d83;
}
