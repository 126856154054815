@import './common/variables';

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 22px;
  font-family: $primary_Font;
  background-color: #ffffff;
  // background-color: #f5f6f8;
  font-weight: normal;
  height: 100vh;
  overflow-x: hidden;
}

.list-title {
  margin-top: 50px;
  margin-bottom: 30px;
}

@media screen and (min-width: 1600px) {
  .container {
    max-width: calc(100% - 320px);
  }
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.modal-open {
  overflow: hidden;
}

.disable {
  color: $ninth;
  border-color: #f8f8f8;

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background: #f8f8f8;
    color: $ninth;
    border-color: #f8f8f8;
  }


  // opacity: 0.7;
  .card-input-check {
    background: #f8f8f8;
    cursor: not-allowed;
  }
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.onError-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

dl,
ol,
ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.h1,
h1 {
  font-size: 34px;
  line-height: 40px;
}

.h2,
h2 {
  font-size: 30px;
  line-height: 36px;
}

.h3,
h3 {
  font-size: 24px;
  line-height: 29px;
}

.h4,
h4 {
  font-size: 20px;
  line-height: 24px;
}

.h5,
h5 {
  font-size: 18px;
  line-height: 22px;
}

.h6,
h6 {
  font-size: 16px;
  line-height: 20px;
}

.font-size-44 {
  font-size: 44px;
  line-height: 52px;
}

p,
.p {
  font-size: 14px;
  line-height: 24px;
}

.small {
  font-size: 12px;
  line-height: 15px;
}

// colors custon classes
.c-tx-primary {
  color: $primary;
}

.c-tx-secondary {
  color: $secondary;
}

.c-tx-third {
  color: $third;
}

.c-tx-forth {
  color: $forth;
}

.c-tx-fifth {
  color: $fifth;
}

.c-tx-sixth {
  color: $sixth;
}

.c-tx-seventh {
  color: $seventh;
}

.c-tx-eight {
  color: $eight;
}

.c-tx-ninth {
  color: $ninth;
}

.c-tx-tenth {
  color: $tenth;
}

.c-tx-eleven {
  color: $eleven;
}

.c-tx-green {
  color: $green;
}

.br-5 {
  border-radius: 5px !important;
}

// background colors custon classes
.c-bg-primary {
  background-color: $primary;
}

.c-bg-secondary {
  background-color: $secondary;
}

.c-bg-third {
  background-color: $third;
}

.c-bg-forth {
  background-color: $forth;
}

.c-bg-fifth {
  background-color: $fifth;
}

.c-bg-sixth {
  background-color: $sixth;
}

.c-bg-seventh {
  background-color: $seventh;
}

.c-bg-eight {
  background-color: $eight;
}

.c-bg-ninth {
  background-color: $ninth;
}

.c-bg-tenth {
  background-color: $tenth;
}

.c-bg-eleven {
  background-color: $eleven;
}

.c-bg-green {
  background-color: $green;
}

// font-weight custom classes
.f-100 {
  font-weight: 100;
}

.f-300 {
  font-weight: 300;
}

.f-400 {
  font-weight: 400;
}

.f-600 {
  font-weight: 600;
}

.f-700 {
  font-weight: 700;
}

.f-900 {
  font-weight: bold;
}

/**/
.lh-17 {
  line-height: 17px;
}

.lh-18 {
  line-height: 18px;
}

.cy-15 {
  padding: 15px 0;
}

.ct-15 {
  padding-top: 15px;
}

.cb-15 {
  padding-bottom: 15px;
}

.cy-20 {
  padding: 20px 0;
}

.ct-20 {
  padding-top: 20px;
}

.cb-20 {
  padding-bottom: 20px;
}

/**/
// constainer full width in responsive
@media screen and (max-width: 1199px) {
  .container {
    max-width: 100%;
  }

  .h1,
  h1 {
    font-size: 28px;
    line-height: 35px;
  }

  .h2,
  h2 {
    font-size: 24px;
    line-height: 30px;
  }

  .h3,
  h3 {
    font-size: 20px;
    line-height: 27px;
  }

  .h4,
  h4 {
    font-size: 18px;
    line-height: 22px;
  }

  .h5,
  h5 {
    font-size: 16px;
    line-height: 20px;
  }

  .h6,
  h6 {
    font-size: 14px;
    line-height: 18px;
  }

  p,
  .p {
    font-size: 12px;
    line-height: 16px;
  }
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.main-content-layout {
  min-height: calc(100vh - 189px);
}

.custom-primary {
  background-color: $secondary;
  font-size: 14px;
  line-height: 20px;
  color: $fifth;
  border: 1px solid $secondary;
  outline: none;
  box-shadow: none;
  padding: 10px;
  border-radius: 3px;
  text-transform: uppercase;

  &.small {
    width: 95px;
    height: 30px;
    border-radius: 5px;
    font-size: 13px;
    line-height: 24px;
    padding: unset;
  }

  &.ext-small {
    width: 76px;
    height: 37px;
    background: #073d83;
    border-radius: 5px;
    padding: unset;
    color: $fifth;
  }

  &.disable {
    background: #f8f8f8;
    color: $ninth;
    border-color: #f8f8f8;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      background: #f8f8f8;
      color: $ninth;
      border-color: #f8f8f8;
    }
  }

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    color: #ffffff;
    background-color: $secondary;
    border: 1px solid $secondary;
    box-shadow: none;
    outline: none;
  }

  &-outline {
    font-size: 14px;
    line-height: 20px;
    border: 1px solid $secondary;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    color: $secondary;
    box-shadow: none;
    padding: 10px;
    border-radius: 3px;
    text-transform: uppercase;

    &.ext-small {
      width: 76px;
      height: 37px;
      border-radius: 5px;
      padding: unset;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      background-color: $secondary;
      border: 1px solid $secondary;
      color: $fifth;
      outline: none;
    }
  }

  &.safron {
    background-color: #f47023 !important;
    color: $white;
    font-size: 14px;
    line-height: 20px;
    color: $fifth;
    border: 1px solid #f47023;
    outline: none;
    box-shadow: none;
    padding: 10px;
    border-radius: 3px;
    text-transform: uppercase;

    &:hover,
    &:focus,
    &:active {
      border: 1px solid #f47023;
      box-shadow: none;
    }
  }
}

.custom-white {
  background-color: $fifth;
  font-size: 14px;
  line-height: 20px;
  color: $third;
  border: 1px solid $fifth;
  outline: none;
  box-shadow: none;
  padding: 10px;
  border-radius: 5px;
  text-transform: uppercase;
  transition: all ease 0.5s;

  &:hover,
  &:focus,
  &:active {
    background-color: #ffffff;
    box-shadow: none;
    transition: all ease 0.5s;
  }
}

.custom-outline-white {
  padding: 10px 20px;
  background-color: transparent;
  font-size: 14px;
  line-height: 20px;
  color: $fifth;
  border: 1px solid #ffffff;
  outline: none;
  box-shadow: none;
  border-radius: 5px;
  text-transform: uppercase;
  transition: all ease 0.5s;

  &:hover,
  &:focus,
  &:active {
    background-color: $forth;
    border: 1px solid $forth;
  }
}

.ct-btn-group {
  button {
    &:first-child {
      margin-right: 5px;
      width: calc(50% - 5px);
    }

    &:nth-child(2) {
      margin-left: 5px;
      width: calc(50% - 5px);
    }
  }
}

.divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.cursor-pointer {
  cursor: pointer;
}

.custom-light-link {
  color: $secondary;
  color: #007de4;
  text-decoration: none;
}

.form-label {
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  margin-bottom: 10px;
}

.form-control,
.form-select {
  border: 1px solid $sixth;
  height: 50px;
  border: 1px solid $sixth;
  border-radius: 10px;
  font-size: 14px;
  line-height: 17px;
  color: $ninth;
  box-shadow: none !important;
  outline: none !important;

  &:focus {
    border-color: $primary;
    color: #868686;
  }
}

textarea {
  &.form-control {
    height: 100px !important;
    resize: none;
  }
}

.inner::-webkit-scrollbar {
  display: none;
}

.border-red {
  border: 1px solid !important;
  border-color: #dc3545 !important;
  border-radius: 5px;
}

// hide invalid form control background image
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size='1'],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size='1'],
.form-control.is-invalid {
  background-image: none !important;
}

.form-select.is-invalid:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
}

// api-wrapper global css
// this will make the api description height fixed so the box can be responsive

// postman button
// .postman-run-button {
//   @extend .custom-primary.safron;
//   background: #f47023 !important;
//   height: auto !important;
// }

/*
*@header dropdown profile design
**/

// postman button
.postman-run-button {
  @extend .custom-primary, .safron;
  background: #f47023 !important;
  height: auto !important;
}

// user profile dropdown style
.header .profile-list {
  width: 300px;
  height: 310px;
  overflow: hidden;
  padding: 0;
  margin-top: 10px;
  z-index: 999999;
}

.header .profile-list .project-list {
  padding: 5px;
}

.header .profile-list .profile-title {
  padding: 4px 0;
  line-height: 12px;
}

.header .profile-list .profile-detail {
  text-align: center;
  margin-top: 20px;
}

.header .profile-list .profile-detail img {
  border-radius: 50%;
  margin-bottom: 10px;
}

.header .profile-list .profile-detail p {
  line-height: 26px;
}

.header .profile-list .profile-detail .manage-button {
  height: 28px;
}

.header .profile-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

.header .profile-footer .logout {
  border-width: 1px 0 1px 0;
  border-color: #dadce0;
  border-style: solid;
  height: 50px;
}

.header .profile-footer .logout button {
  background: transparent;
  margin: 0 10px;
  padding: 5px;
}

.header .profile-footer .policy {
  text-align: center;
  height: 30px;
}

.profile-list .dropdown-item:active {
  background: none;
}

.profile-list .dropdown-item:hover {
  background-color: transparent;
}

// .postman-run-button {
//   @extend .custom-primary.safron;
//   background: #f47023 !important;
//   height: auto !important;
// }

// global scroll bars style
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $sixth;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary;
}

.modal-backdrop {
  // background-image: url("../Images/try-2.svg");
  background: #1f4b84;
  opacity: 0.95 !important;
}

.confirmation-model-parent.modal-backdrop {
  background: #1f4b84;
  opacity: 0.3 !important;
}

.confirmation-modal {
  max-width: 450px !important;
  min-height: 150px !important;
  border-radius: 4px !important;
  overflow: hidden;
}

.confirmation-button {
  background-color: #fff;
  font-size: 18px;
  margin: auto;
}

.point-list {
  list-style-type: none;

  .icon {
    width: 20px;
    position: absolute;
    top: 0;
    left: 0;
  }

  li {
    padding-left: 25px;
    position: relative;
  }
}

// kyc styles
.status-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  // position: absolute;
  // left: -3px;
  // top: 50%;
  // transform: translateY(-50%);
}

.status-indicator.active {
  background-color: #28c76f;
  color: #28c76f;
}

.status-indicator.deactive {
  background-color: #ea2027;
  color: #ea2027;
}

.status-indicator.pending {
  background-color: #eaac20;
  color: #eaac20;
}

.active {
  color: #28c76f;
}

.deactive {
  color: #ea2027;
}

.pending {
  color: #eaac20;
}

/*common pop up css */
/*pop up portion start*/
.custom-stepper-popup {
  .modal-content {
    background-color: transparent;
    border-color: transparent;
  }

  .stepper-link-wrapper {
    position: absolute;
    top: -90px;
    width: 100%;
    left: 0;
  }

  .custom-card-wrapper {
    height: 520px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 20px;
  }

  .token-engine-1 {
    min-height: 500px !important;
    height: 528px;
    max-height: 800px !important;
  }
}

.modal-close {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 111;
  color: white;
  text-decoration: none;
}

// hide number input icons arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.primaryButton {
  &:hover {
    background: $secondary;
  }
}

// border primary
.border-color-primary {
  border-color: $third !important;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-stepper-popup {
    max-width: calc(100% - 30px);
    height: 500px;
  }
}

@media screen and (max-width: 992px) {
  .custom-stepper-popup {
    .custom-card-wrapper {
      height: auto;
    }
  }
}

/*pop up portion end*/

/*Thank you pop up */
.confirm-popup-wrapper {
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content-title {
    margin-top: 20px;

    .h1 {
      font-size: 50px;
      line-height: 55px;
    }
  }

  .h5,
  h5 {
    line-height: 26px;
  }

  .footer-bl {
    position: absolute;
    bottom: 0;
  }

  @media screen and (max-width: 992px) {
    .content-title {
      margin-top: 0px;

      .h1 {
        font-size: 28px;
        line-height: 32px;
      }
    }

    svg {
      width: 50px;
    }

    .h5,
    h5 {
      line-height: 24px;
    }

    .content {
      margin-top: 0;
    }
  }
}

.pagination-wrap {
  padding: 0 10px;
  margin-top: 20px;

  .pagination-link-wrapper {
    display: flex;
    justify-content: flex-end;

    button {
      color: $secondary;
      background-color: transparent;
      opacity: 0.3;
      &.active {
        opacity: 0.8;
      }
      &:hover {
        opacity: 0.8;
      }
      &:disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}

.new-btn {
  padding: 10px 20px;
}

.footer-active-wrap {
  .footer-wrapper {
    margin: 0px 0;
    padding: 0px 0;
  }

  .container {
    max-width: 100%;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 50px;
  }
}

.mobile-footer {
  display: none;
  background: 'red';
  z-index: 9;
  position: fixed;
  width: 100%;

  justify-content: space-around;
  text-align: center;
  bottom: 0;
  background-color: $primary;
  padding: 10px;

  @media screen and (max-width: 767px) {
    display: flex;
  }

  li {
    width: 25%;
    color: #ffffff;
    opacity: 0.6;

    .icon {
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }

    .text-ctn {
      font-size: 10px;
      line-height: 13px;
      transition: all ease 0.2s;
    }

    &.active,
    &:hover {
      opacity: 1;
    }

    @media screen and (max-width: 767px) {
      width: auto;
    }

    @media screen and (max-width: 575px) {
      width: 25%;
    }
  }
}

.table-sticky {
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 2;
}

.custom-link {
  color: $secondary;
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  padding-left: 5px;

  // background: white;
  &:hover {
    color: $third;
  }
}

.section-margin-y {
  margin: 100px 0;
}

.coming-soon-wrapper {
  min-height: calc(100vh - 289px);

  h3 {
    font-size: 40px;
    line-height: 40px;
    font-weight: bold;

    @media screen and (max-width: 767px) {
      font-size: 26px;
    }
  }

  p {
    font-size: 26px;

    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }

  .under-construction-image {
    @media screen and (max-width: 1024px) {
      max-width: 500px;
    }

    @media screen and (max-width: 767px) {
      max-width: 250px;
    }
  }

  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 144px);
  }
}

.under-maintanance-wrapper {
  height: calc(100vh - 80px);
  background-color: #073d83;
  border-radius: 20px;
  background-image: url(../assets/Images/under-maintenance.svg);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center right 100px;

  .under-maintenance-content {
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 100px;
  }

  h3 {
    font-size: 80px;
    font-weight: bold;
    line-height: 82px;
    color: white;
    text-align: left;
    opacity: 0.1;

    @media screen and (max-width: 767px) {
      font-size: 26px;
      line-height: 28px;
      text-align: center;
    }
  }

  p {
    font-size: 26px;
    color: white;
    text-align: left;
    opacity: 0.1;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      text-align: center;
    }
  }

  .underMain-Img {
    margin-left: -60px !important;
    display: flex;
    justify-content: flex-end;
  }

  .under-construction-image {
    @media screen and (max-width: 1024px) {
      max-width: 500px;
    }

    @media screen and (max-width: 767px) {
      max-width: 250px;
      margin-top: 60px !important;
    }
  }

  @media screen and (max-width: 768px) {
    background-image: none;

    .under-maintenance-content {
      margin: auto;
      align-items: center;
    }
  }

  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 144px);
  }
}

.customUserLogo {
  color: white;
  background: #2f55a6;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  overflow: hidden;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 8px;
  }
}

.get-api-color {
  color: #61affe;
}

.post-api-color {
  color: #49cc90;
}

.put-api-color {
  color: #fca130;
}

.patch-api-color {
  color: #50e3c2;
}

.delete-api-color {
  color: #f93e3e;
}

.sc-crozmw {
  width: 100%;
}

.w-210 {
  width: 210px;
}

/* Custom CSS */
.hide-highcharts-tag .highcharts-container .highcharts-contextbutton {
  display: none !important;
}

.secretKey {
  filter: blur(5px);
  -webkit-filter: blur(5px);
  user-select: none;
}

.revoke {
  background-color: #d2d2e34a;
  padding: 9px 16px;
}

.button {
  font-weight: 600;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}